<template>
  <section class="users">
    <Header @toggleFilters="toggleFilters" />
    <span ref="userstop"></span>
    <div class="container">
      <Pagination :pages="pageCount" :current="firstSlice" @changePage="changePage" />
    </div>
    <div class="margin__md">
      <div class="users__grid container">
        <transition name="filter-slide">
          <FiltersForm
            @toggleFilters="toggleFilters"
            @resetPage="resetPage"
            v-if="filtersToggled"
            class="small--filters"
            :userFilters="getUserFilters"
          />
        </transition>
        <FiltersForm class="large--filters" @resetPage="resetPage" :userFilters="getUserFilters" />
        <Users :users="displayedUsers" />
      </div>
    </div>
    <div class="container">
      <Pagination :pages="pageCount" :current="firstSlice" @changePage="changePage" />
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "./Header";
import FiltersForm from "./FiltersForm";
import Users from "./Users";
import Pagination from "../../Pagination";
export default {
  components: {
    Header,
    FiltersForm,
    Users,
    Pagination
  },
  metaInfo: {
    title: "Users | WOW"
  },
  data() {
    return {
      filtersToggled: false,
      displayUsers: 16,
      firstSlice: 0
    };
  },
  computed: {
    ...mapGetters(["getFilteredUsers", "getUserFilters"]),
    userCount() {
      return this.getFilteredUsers.length;
    },
    pageCount() {
      return Math.ceil(this.userCount / this.displayUsers);
    },
    displayedUsers() {
      // pass these into products component
      return this.getFilteredUsers.slice(
        this.firstSlice * this.displayUsers,
        this.firstSlice * this.displayUsers + this.displayUsers
      );
    }
  },
  methods: {
    toggleFilters() {
      this.filtersToggled = !this.filtersToggled;
    },
    changePage(index) {
      if (index === 0) {
        this.firstSlice = 0;
      }
      if (index === 1) {
        if (this.firstSlice === 0) {
          return;
        }
        this.firstSlice -= 1;
      }
      if (index === 2) {
        if (this.firstSlice + 1 === this.pageCount) {
          return;
        }
        this.firstSlice += 1;
      }
      if (index === 3) {
        this.firstSlice = this.pageCount - 1;
      }
      this.addRouteParams();
      this.scrollToTop();
    },
    scrollToTop() {
      this.$refs.userstop.scrollIntoView({
        block: "nearest",
        behavior: "smooth"
      });
    },
    addRouteParams() {
      let queryChar = this.$route.fullPath.includes("?") ? "&" : "?";
      if (this.$route.fullPath.includes("page=")) {
        this.$router.push(
          `${this.$route.fullPath.replace(
            /page=\d/,
            `${queryChar}page=${this.firstSlice + 1}`
          )}`
        );
      } else {
        this.$router.push(
          `${this.$route.fullPath}${queryChar}page=${this.firstSlice + 1}`
        );
      }
    },
    resetPage() {
      this.firstSlice = 0;
      if (this.$route.query.page) return this.$router.replace({ query: null });
    }
  },
  mounted() {
    if (this.$route.query.page) {
      this.firstSlice = this.$route.query.page - 1;
    }
  }
};
</script>

<style lang="scss" scoped>
@media (min-width: $md) {
  .users__grid {
    @include grid($cols: 1fr 2fr, $col-gap: 25px);
  }
}
</style>