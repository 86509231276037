<template>
  <div class="filters">
    <div class="flex__between">
      <h2>Filter and Sort</h2>
      <span class="breadcrumb" @click="reset">Reset</span>
    </div>
    <div class="flex__between p-tb-sm">
      <button class="btn btn__sm btn__red" @click="emitToggleFilters">Exit</button>
      <button class="btn btn__sm btn__frost" @click="submit">Submit</button>
    </div>
    <simplebar class="filters--overflow" data-simplebar-auto-hide="false">
      <div class="filters--form" @keypress.enter="submit">
        <label>
          Name
          <input type="text" v-model="form.name" placeholder="first and/or last" />
        </label>
        <label>
          Email
          <input type="email" v-model="form.email" placeholder="person@fbhl.com" />
        </label>
        <div class="permissions--select flex__between" @click="togglePermissions">
          <span>Permissions</span>
          <span>
            <font-awesome-icon
              v-if="!permissionsToggled"
              icon="caret-down"
              size="sm"
              class="ml-10"
            />
            <font-awesome-icon v-else icon="caret-up" size="sm" class="ml-10" />
          </span>
        </div>
        <ul v-if="permissionsToggled" class="permissions--list">
          <li v-for="option in permissionOptions" :key="option">
            <label>
              <input type="checkbox" :value="option" v-model="form.permission" />
              {{ option }}
            </label>
          </li>
        </ul>
        <label>
          Is Active
          <select v-model="form.isActive">
            <option value>Any</option>
            <option :value="true">Yes</option>
            <option :value="false">No</option>
          </select>
        </label>
        <label>
          Batch Enabled
          <select v-model="form.bulkEnabled">
            <option value>Any</option>
            <option :value="true">Yes</option>
            <option :value="false">No</option>
          </select>
        </label>
        <label>
          Name Sort
          <select v-model="form.sort">
            <option value="a-z">A-Z</option>
            <option value="z-a">Z-A</option>
          </select>
        </label>
      </div>
    </simplebar>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["userFilters"],
  data() {
    return {
      permissionsToggled: false,
      permissionOptions: ["user", "admin", "super-admin", "management", "asm"],
      form: {
        name: this.userFilters ? this.userFilters.name : "",
        email: this.userFilters ? this.userFilters.email : "",
        permission: this.userFilters ? this.userFilters.permission : [],
        isActive: this.userFilters ? this.userFilters.isActive : null,
        bulkEnabled: this.userFilters ? this.userFilters.bulkEnabled : null,
        sort: this.userFilters ? this.userFilters.sort : "a-z"
      }
    };
  },
  watch: {
    userFilters: {
      handler: function(newVal) {
        this.form = JSON.parse(JSON.stringify(newVal));
      },
      deep: true
    }
  },
  methods: {
    ...mapActions(["startSetUserFilters", "startUnsetUserFilters"]),
    togglePermissions() {
      this.permissionsToggled = !this.permissionsToggled;
    },
    emitToggleFilters() {
      this.$emit("toggleFilters");
    },
    submit() {
      this.startSetUserFilters(this.form);
      this.$emit("resetPage");
      this.emitToggleFilters();
    },
    reset() {
      this.startUnsetUserFilters();
      this.$emit("resetPage");
      this.emitToggleFilters();
    },
    setName() {
      if (this.$route.query.name) {
        const name = decodeURI(this.$route.query.name);
        this.form.name = name;
      }
    },
    setEmail() {
      if (this.$route.query.email) {
        this.form.email = this.$route.query.email;
      }
    },
    setPermission() {
      if (this.$route.query.permission) {
        if (this.permissionOptions.includes(this.$route.query.permission)) {
          this.form.permission.push(this.$route.query.permission);
        }
      }
    },
    setIsActive() {
      if (this.$route.query.active) {
        this.form.isActive = this.$route.query.active === "true";
      }
    },
    setBulkEnabled() {
      if (this.$route.query.bulk) {
        this.form.bulkEnabled = this.$route.query.bulk === "true";
      }
    }
  },
  mounted() {
    this.setName();
    this.setEmail();
    this.setPermission();
    this.setIsActive();
    this.setBulkEnabled();
    this.startSetUserFilters(this.form);
  }
};
</script>

<style scoped lang="scss">
.permissions--select {
  margin-top: 5px;
  padding: 10px;
  border: 1px solid $black;
  border-radius: 5px;
  background-color: $frost;
}
.permissions--list {
  padding: 10px;
  @include grid($cols: 1fr, $row-gap: 10px);
}
.permissions--list li label {
  @include grid($cols: 1fr 7fr, $col-gap: 10px, $align: center);
}
.breadcrumb {
  padding-top: 0;
}
@media (min-width: $md) {
  .btn__red {
    display: none;
  }
}
</style>