<template>
  <section class="users-header">
    <div class="container">
      <div class="flex__between">
        <router-link to="/admin" class="breadcrumb">
          <font-awesome-icon icon="arrow-left" size="sm" class="mr-10" />Back to Admin
        </router-link>
        <router-link to="/admin/users/new" class="btn btn__sm btn__green mt-15">
          New User
          <font-awesome-icon icon="arrow-right" size="sm" class="ml-10" />
        </router-link>
      </div>
      <h1 class="text__center padding__sm">WOW Users</h1>
      <p class="header--message mb-15">
        A person must have a profile in order to complete an order on this site. Also, the profile email must match their current email address
        <i>exactly</i> in order for them to log in.
        <br />
        <br />
        <i>Please do not create test users as deleting them will be very difficult and can potentially cause issues with order data</i>
      </p>
      <FilterButton
        @toggleFilters="emitToggleFilters"
        @clearFilters="clearFilters"
        class="small--filters"
      />
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import FilterButton from "../../FilterButton";
export default {
  components: {
    FilterButton
  },
  methods: {
    ...mapActions(["startUnsetUserFilters"]),
    emitToggleFilters() {
      this.$emit("toggleFilters", true);
    },
    clearFilters() {
      this.startUnsetUserFilters();
    }
  }
};
</script>

<style lang="scss" scoped>
.users-header__grid {
  @include grid($cols: 1fr 3fr, $row-gap: 10px, $col-gap: 25px, $align: center);
}
@media (min-width: $md) {
  .users-header__grid {
    display: block;
  }
}
</style>