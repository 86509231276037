<template>
  <section>
    <p
      v-if="users.length <= 0"
      class="text__center text__bold padding__md"
    >No results returned for your search</p>
    <ul v-else class="users-list">
      <User v-for="user in users" :key="user._id" :user="user" />
    </ul>
  </section>
</template>

<script>
import User from "./User";
export default {
  props: ["users"],
  components: {
    User
  }
};
</script>

<style scoped lang="scss">
.users-list {
  @include grid($cols: 1fr, $row-gap: 25px, $col-gap: 25px);
  height: fit-content;
}
@media (min-width: $lg) {
  .users-list {
    grid-template-columns: 1fr 1fr;
  }
}
</style>