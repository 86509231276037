<template>
  <li class="users-item tile pos__rel">
    <div class="avatar" :class="`avatar-${avatarNum}`">
      <span class="text__powder">{{ initials }}</span>
    </div>
    <span class="text__bold text__md text__center">{{ user.displayName }}</span>
    <p>
      <span class="text__bold">Email:</span>
      <span class="text__overflow text__right">
        <a :href="`mailto: ${user.email}`" class="link__none">{{ user.email }}</a>
      </span>
    </p>
    <p>
      <span class="text__bold">Phone:</span>
      <span class="text__overflow text__right">
        <a :href="`tel: ${user.phone}`" class="link__none">{{ user.phone }}</a>
      </span>
    </p>
    <p>
      <span class="text__bold">Permission:</span>
      <span class="text__overflow text__right">{{ user.permission }}</span>
    </p>
    <p>
      <span class="text__bold">Enabled:</span>
      <span class="text__overflow text__right">{{ user.enabled ? "Yes" : "No" }}</span>
    </p>
    <p>
      <span class="text__bold">Award Balance:</span>
      <span class="text__overflow text__right">${{ user.awardBalance.toFixed(2) }}</span>
    </p>
    <div class="flex__right">
      <router-link :to="`/admin/users/${user._id}`" class="breadcrumb text__bold">
        View Profile
        <font-awesome-icon icon="arrow-right" size="sm" class="ml-10" />
      </router-link>
    </div>
    <Bulk :status="user.bulkEnabled" />
  </li>
</template>

<script>
import Bulk from "./Bulk";
export default {
  props: ["user"],
  components: {
    Bulk
  },
  data() {
    return {
      avatarNum: Math.floor(Math.random() * 4) + 1
    };
  },
  computed: {
    initials() {
      const first = this.user.name.first.split("")[0].toUpperCase();
      const last = this.user.name.last.split("")[0].toUpperCase();
      return `${first}${last}`;
    }
  }
};
</script>

<style scoped lang="scss">
.users-item {
  background-color: lighten($frost, 5%);
  @include grid($cols: 1r, $row-gap: 10px);
  /* border: 1px solid $sky; */
  transition: all 0.25s ease-in-out;
  p {
    @include grid($cols: 1fr 1fr, $col-gap: 5px, $align: center);
  }
}
.users-item:hover {
  background-color: lighten($frost, 10%);
  @include shadow;
}
.users-item--head {
  @include grid($cols: 1fr 50px, $col-gap: 10px, $align: center, $just: center);
  margin-bottom: 15px;
}
.users-item--content {
  @include grid($cols: 1fr, $row-gap: 10px);
  p {
    @include grid($cols: 1fr 1fr, $col-gap: 5px, $align: center);
  }
}
</style>