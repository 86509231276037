<template>
  <div class="users-item--bulk text__center">
    <span v-if="status" class="text__powder text__bold">Batch User</span>
    <span v-else class="text__powder text__bold">Non-Batch</span>
  </div>
</template>

<script>
export default {
  props: ["status"],
  computed: {}
};
</script>

<style scoped lang="scss">
.users-item--bulk {
  @include pos-abs($pos: absolute, $top: -5px, $left: -5px, $width: 50%);
  padding: 2px 0;
  padding-right: 25px;
  margin: 0 !important;
  clip-path: polygon(0% 0%, 100% 0, 91% 100%, 0% 100%);
  background-color: $peacock;
}
</style> 